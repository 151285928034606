<script>
import LeftNavigation from "@/components/LeftNavigation.vue";

export default {
  components: {LeftNavigation}
}
</script>

<template>
  <div class="statistics">
    <LeftNavigation :menu="[
      {name: 'stat_order', ti: '订单统计'},
      {name: 'stat_expense', ti: '个人应收款'},
      {name: 'stat_vehicle', ti: '车辆统计'}
    ]"></LeftNavigation>
    <section>
      <router-view></router-view>
    </section>
  </div>
</template>

<style scoped lang="less">
.statistics{
  display: flex;
  height: 100%;
  overflow: hidden;
}
section{
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 200px);
  padding: 20px;
  display: flex;
  flex-direction: column;
}
</style>
